import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ListResult } from './cagehunter-fighter.service';
import { take, map } from 'rxjs/operators';
import { CagehunterAlgoliaService } from './cagehunter-algolia.service';
import * as algoliasearch from 'algoliasearch';
import { UserStatus, UserData, UserFilter } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class CagehunterUserService {

  constructor(
    private _afDatabase: AngularFireDatabase,
    private _algoliaService: CagehunterAlgoliaService
  ) { }

  private _buildAlgoliaQuery(filterData: UserFilter): string {
    const arr = [];
    if (filterData.professionType) {
      arr.push(`profession:${filterData.professionType}`);
    }
    if (filterData.subscriptionType) {
      arr.push(`subscriptionType:${filterData.subscriptionType}`);
    }
    if (filterData.status) {
      arr.push(`status:${filterData.status}`);
    }

    return arr.join(' AND ');
  }

  async getAllByFilter(filterData: UserFilter, page: number = 0, limit: number = 50): Promise<ListResult<UserData>> {
    const algoliaParams: algoliasearch.QueryParameters = {
      query: filterData.search,
      filters: this._buildAlgoliaQuery(filterData),
      hitsPerPage: limit,
      page
    };
    this._algoliaService.usersIndex.clearCache();
    const response = await this._algoliaService.usersIndex.search(algoliaParams);
    return {
      data: response.hits,
      totalCount: response.nbHits
    };
  }

  async get(userId: string): Promise<UserData> {
    return this._afDatabase.object(`/users/${userId}`).snapshotChanges().pipe(
      take(1),
      map((action) => <UserData>{
        id: action.key,
        ...action.payload.val()
      })
    ).toPromise();
  }

  async update(user: UserData): Promise<void> {
    return this._afDatabase.object(`/users/${user.id}`).update(user);
  }

  async activate(userId: string): Promise<void> {
    return this._afDatabase.object(`/users/${userId}/status`).set(UserStatus.ACTIVE);
  }

  async disable(userId: string): Promise<void> {
    return this._afDatabase.object(`/users/${userId}/status`).set(UserStatus.DISABLED);
  }
}
